import { Suspense, lazy, useEffect } from 'react';
import {
	Navigate,
	Outlet,
	Route,
	BrowserRouter as Router,
	Routes,
} from 'react-router-dom';
import BuyersPage from 'src/pages/buyers';
import LearningToPlayInstrumentsPage from 'src/pages/learning-to-play-instruments';
import PracticesPage from 'src/pages/practice';
import PracticeSlugPage from 'src/pages/practice-slug';
import CompanyRetreatsPage from 'src/pages/сompany-retreats';
import { Langs, RoutsPath } from '../@types';
import i18n from '../i18n';
import BookingConfirmedPage from '../pages/booking-confirmed';
import CartPage from '../pages/cart';
import CatalogPage from '../pages/catalog';
import CatalogAudioPage from '../pages/catalog-audio';
import CatalogSlugPage from '../pages/catalog-slug';
import HomePage from '../pages/home';
import OrderPage from '../pages/order';
import OrdersPage from '../pages/orders';
import PrivacyPolicyPage from '../pages/privacy-policy';
import ProfilePage from '../pages/profile';
import ProfileRecordsPage from '../pages/profile-records';
import SchedulePage from '../pages/schedule';
import SomethingWentWrongPage from '../pages/something-went-wrong';

const NotFoundPage = lazy(() => import('../pages/not-found/index'));

const NotFoundPageSuspense = () => (
	<Suspense fallback={<div />}>
		<NotFoundPage />
	</Suspense>
);

const onChangeLang = (lang: string) => {
	i18n.changeLanguage(lang);
	window.localStorage.setItem('i18nextLng', lang);
};

const CheckLangRoutes = () => {
	const lang: string | null = window.localStorage.i18nextLng;
	const isRu = window.location.pathname.includes(`/${Langs.ru}`);
	const isEn = window.location.pathname.includes(`/${Langs.en}`);

	useEffect(() => {
		if (isRu && lang) {
			onChangeLang(Langs.ru);
		} else if (isEn && lang) {
			onChangeLang(Langs.en);
		} else {
			onChangeLang(lang || Langs.en);
		}
	}, [isRu, isEn, lang]);

	if (isRu && lang) {
		return <Outlet />;
	}

	if (isEn && lang) {
		return <Navigate to={RoutsPath.home} />;
	}

	return <Navigate to={RoutsPath.not_found_base} />;
};

export function ApplicationRouter() {
	const isAuth = true;
	const PrivateRoutes = () => {
		const lang: string | null = window.localStorage.i18nextLng;
		return isAuth ? (
			<Outlet />
		) : (
			<Navigate to={lang === 'en' ? '/' : `${lang}/`} />
		);
	};

	useEffect(() => {
		const lang: string | null = window.localStorage.i18nextLng;
		const isRu = window.location.pathname.includes(`/${Langs.ru}`);

		if (lang && isRu && lang !== Langs.ru) {
			onChangeLang(Langs.ru);
		} else if (lang && lang !== Langs.en && !isRu) {
			onChangeLang(Langs.en);
		}
	}, []);

	//TODO: СДЕЛАТЬ ОБЪЕКТ МАССИВОВ РОУТОВ
	return (
		<Router>
			<Routes>
				<Route>
					<Route index path={'/'} element={<HomePage />} />
					<Route path={RoutsPath.schedule} element={<SchedulePage />} />
					<Route path={RoutsPath.audio} element={<CatalogAudioPage />} />
					<Route path={RoutsPath.cart}>
						<Route index element={<CartPage />} />
					</Route>
					<Route path={RoutsPath.catalog}>
						<Route index element={<CatalogPage />} />
						<Route path=":slug" element={<CatalogSlugPage />} />
					</Route>
					<Route
						path={`${RoutsPath.privacy_policy}`}
						element={<PrivacyPolicyPage />}
					/>
					<Route path={RoutsPath.practice}>
						<Route index element={<PracticesPage />} />
						<Route path=":slug" element={<PracticeSlugPage />} />
					</Route>
					<Route path={RoutsPath.order} element={<OrderPage />} />
					<Route
						path={RoutsPath.company_retreats}
						element={<CompanyRetreatsPage />}
					/>
					<Route
						path={RoutsPath.learning_to_play_instruments}
						element={<LearningToPlayInstrumentsPage />}
					/>
					<Route path={RoutsPath.buyers} element={<BuyersPage />} />
					<Route
						path={`${RoutsPath.not_found_base.slice(1)}`}
						element={<NotFoundPageSuspense />}
					/>
					<Route path={RoutsPath.booking_confirmed} element={<BookingConfirmedPage />} />
					<Route path={RoutsPath.something_went_wrong} element={<SomethingWentWrongPage />} />
				</Route>

				<Route element={<CheckLangRoutes />}>
					<Route index path={':lang'} element={<HomePage />} />
					<Route
						path={`:lang${RoutsPath.schedule}`}
						element={<SchedulePage />}
					/>
					<Route
						path={`:lang${RoutsPath.privacy_policy}`}
						element={<PrivacyPolicyPage />}
					/>
					<Route
						path={':lang' + RoutsPath.audio}
						element={<CatalogAudioPage />}
					/>
					<Route path={':lang' + RoutsPath.catalog}>
						<Route index element={<CatalogPage />} />
						<Route path=":slug" element={<CatalogSlugPage />} />
					</Route>
					<Route path={':lang' + RoutsPath.cart}>
						<Route index element={<CartPage />} />
					</Route>
					<Route path={':lang' + RoutsPath.practice}>
						<Route index element={<PracticesPage />} />
						<Route path=":slug" element={<PracticeSlugPage />} />
					</Route>
					<Route path={':lang' + RoutsPath.order} element={<OrderPage />} />
					<Route
						path={':lang' + RoutsPath.company_retreats}
						element={<CompanyRetreatsPage />}
					/>
					<Route
						path={':lang' + RoutsPath.learning_to_play_instruments}
						element={<LearningToPlayInstrumentsPage />}
					/>
					<Route path={':lang' + RoutsPath.buyers} element={<BuyersPage />} />
					<Route path={':lang' + RoutsPath.booking_confirmed} element={<BookingConfirmedPage />} />
					<Route path={':lang' + RoutsPath.something_went_wrong} element={<SomethingWentWrongPage />} />
					{/* ПРИВАТНЫЕ ДЛЯ МАРШРУТОВ С ЯЗЫКОМ */}
					<Route element={<PrivateRoutes />}>
						<Route
							index
							path={':lang' + RoutsPath.profile}
							element={<ProfilePage />}
						/>
						<Route
							path={':lang' + RoutsPath.events_profile}
							element={<ProfileRecordsPage />}
						/>
						<Route
							path={':lang' + RoutsPath.orders_profile}
							element={<OrdersPage />}
						/>
					</Route>
					<Route
						path={`:lang${RoutsPath.not_found_base}`}
						element={<NotFoundPageSuspense />}
					/>
				</Route>
				{/* ПРИВАТНЫЕ ДЛЯ МАРШРУТА БЕЗ ЯЗЫКА */}
				<Route element={<PrivateRoutes />}>
					<Route index path={RoutsPath.profile} element={<ProfilePage />} />
					<Route
						path={RoutsPath.events_profile}
						element={<ProfileRecordsPage />}
					/>
					<Route path={RoutsPath.orders_profile} element={<OrdersPage />} />
				</Route>
				<Route path={RoutsPath.not_found} element={<NotFoundPageSuspense />} />
			</Routes>
		</Router>
	);
}
