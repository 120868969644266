import { parse } from 'date-fns';
import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { IMasterData, IScheduleItemData } from '../../../@types';
import Masters from '../../masters/masters.component';
import Stepper from '../../ui/stepper/stepper.component';
import ActionButton from './common/action-button.component';
import Divider from './common/divider.component';
import EventDateInfo from './common/event-data-info.component';
import EventDescription from './common/event-description.component';
import EventPriceInfo from './common/event-price-info.component';
import LocationInfo from './common/location-info.component';
import { useAuth } from '../../../application/application.auth.context';
import { ModalAuthContext } from '../../modals/modal-auth/modal-auth.context';
import { CURRENCY } from 'src/const/settings.const';
import { apiServices } from '../../../services/api';
import { convertDotDate, dateFnsParse } from '../../../utils/date.utils';
import cl from './event-info-modal-content.module.scss';

interface EventContentInfoProps {
	event: IScheduleItemData;
	setEvent: (value: IScheduleItemData | null) => void;
	onUpdateEvent: () => void;
	onClickMaster: (value: IMasterData) => void;
}

const EventInfoModalContent = ({
	event,
	setEvent,
	onClickMaster,
}: EventContentInfoProps) => {
	const { toggleOpen } = useContext(ModalAuthContext);
	const navigate = useNavigate();
	const location = useLocation();
	const { isAuth, user } = useAuth();
	const { i18n, t } = useTranslation();
	const [countTickets, setCountTickets] = useState(1);

	const isPastEvent = useMemo(() => {
		if (event && event.date && event.time_finish) {
			const fullDateString = `${event.date.split(' ')[0]} ${event.time_finish}`;
			const eventDateTime = parse(
				fullDateString,
				'dd.MM.yyyy HH:mm:ss',
				new Date(),
			);
			if (isNaN(eventDateTime.getTime())) {
				console.error('Invalid event date format:', fullDateString);
				return false;
			}
			return eventDateTime < new Date();
		}
		return false;
	}, [event]);

	const soldOut = useMemo(() => {
		return event?.max_users === event?.bookings_count;
	}, [event]);

	const isUserRecorder = useMemo(() => {
		return (
			event.user_bookings &&
			event.user_bookings.some((item) => item.status === 'BOOK')
		);
	}, [event.user_bookings]);

	const generateButtonText = useMemo(() => {
		if (isPastEvent) return t('btn.label.past.event');
		if (soldOut) return t('btn.event.modal.soldOut');

		return t('btn.event.modal');
	}, [isPastEvent, t, soldOut]);

	const isPrice = useMemo(() => {
		return (
			event &&
			event?.price !== '0.00' &&
			event?.price !== '0.0' &&
			event.price !== '' &&
			event.price !== '0' &&
			event.price !== undefined
		);
	}, [event]);

	const onSingUp = async () => {
		try {
			const data = {
				schedule: +event.id,
				count: countTickets,
			};

			const response = await apiServices.scheduleBooking(data);

			if (response.payment_link) {
				window.location.href = response.payment_link;
			}
		} catch (error) {
			console.error('Error during participation update:', error);
		}
	};

	const date_string = event?.date.split(' ');
	const time_finish = event?.time_finish ? event.time_finish.split(':') : null;
	const time_start = date_string ? date_string[1].split(':') : null;

	const { week, day, month } = dateFnsParse(
		event?.date && date_string ? convertDotDate(date_string[0]) : null,
		i18n.language,
		{
			replace_week_ru: true,
		},
	);

	const numberOfTicketsTheCurrentUser = useMemo(() => {
		return event?.user_bookings.reduce(
			(acc, currentValue) => acc + currentValue.count,
			0,
		);
	}, [event?.user_bookings]);

	const maxStepper = useMemo(() => {
		return event.max_users - event.bookings_count;
	}, [event.max_users, event.bookings_count]);

	return (
		<div
			className={`${cl.eventContentInfo} ${isUserRecorder ? cl.eventContentInfo__active : ''}`}
		>
			{!isPastEvent && (
				<div className={cl.tagAndBookingCountWrap}>
					<div className={cl.tag}>
						<span>{t('you.recorded')}</span>
					</div>
					{numberOfTicketsTheCurrentUser && (
						<div className={cl.bookingsCount}>
							<img src="/image/ticket.svg" alt="" />
							{numberOfTicketsTheCurrentUser}
						</div>
					)}
				</div>
			)}

			<h4 className={cl.title}>{event.title}</h4>

			{event.event_location && (
				<LocationInfo eventLocation={event.event_location} />
			)}

			<div className={cl.event__info}>
				<EventDateInfo
					week={week}
					day={day}
					month={month}
					timeStart={time_start}
					timeFinish={time_finish}
				/>
				<EventPriceInfo
					isPrice={isPrice}
					price={event.price}
					usersCount={event.bookings_count}
					setCountTickets={setCountTickets}
					maxUsers={event.max_users}
					currency={CURRENCY}
				/>
			</div>

			{!isPastEvent && maxStepper > 0 && (
				<div className={cl.stepperMobile}>
					<Stepper min={1} max={maxStepper} onChange={setCountTickets} />
				</div>
			)}

			<div className={cl.buttonWrapper}>
				<ActionButton
					isPastEvent={isPastEvent || soldOut}
					disabled={soldOut || isPastEvent}
					onClick={onSingUp}
					text={generateButtonText}
				/>
			</div>

			<Divider />

			<Masters masters={event.masters} onClickMaster={onClickMaster} />

			<Divider />

			<EventDescription description={event.description} />
		</div>
	);
};

export default EventInfoModalContent;
