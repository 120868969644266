import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button } from '../ui/buttons/button/button.component';
import cl from './feedback-section.module.scss';

interface FeedbackSectionProps {
	type?: 'success' | 'error';
	title: string;
	message: string;
}

const FeedbackSection = ({
	title,
	message,
	type = 'success',
}: FeedbackSectionProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const handleGoMainPage = () => {
		navigate('/');
	};

	const handleGoShedule = () => {
		navigate('/schedule');
	};

	return (
		<div className={cl.feedbackSection}>
			<div className={cl.imageWrapper}>
				{type === 'success' && (
					<video className={`${cl.video} ${cl.videoSuccess}`} autoPlay loop muted>
						<source src="/video/gradient-success.mp4" type="video/mp4" />
					</video>
				)}
				{type === 'error' && (
					<video className={`${cl.video} ${cl.videoError}`} autoPlay loop muted>
						<source src="/video/gradient-error.mp4" type="video/mp4" />
					</video>
				)}
			</div>
			<h1 className={cl.title}>{title}</h1>
			<p
				className={cl.description}
				dangerouslySetInnerHTML={{ __html: message }}
			></p>

			<div className={cl.buttonsWrapper}>
				<Button className={cl.buttonLink} onClick={handleGoMainPage}>{t('home.page')}</Button>
				<Button className={cl.buttonLink} theme="outline" onClick={handleGoShedule}>
					{t('home.schedule')}
				</Button>
			</div>
		</div>
	);
};

export default FeedbackSection;
