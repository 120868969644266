import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import FeedbackSection from '../../components/feedback-section/feedback-section.component';
import { PageLayout } from '../../components/layout/page.layout';
import { Breadcrumbs } from '../../components/ui/breadcrumbs/breadcrumbs.component';
import cl from './index.module.scss';

const BookingConfirmedPage = () => {
	const { t } = useTranslation();

	useEffect(() => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	}, []);

	return (
		<PageLayout positionHeader="fixed">
			<div className={cl.page}>
				<div className={'header-offset'} />
				<div className="container">
					<div className={'box20'}></div>
					<Breadcrumbs
						items={[
							{
								name: t('pages.booking-confirmed.page-name'),
								translate: true,
							},
						]}
					/>
					<h1 className={'title-section-small ' + cl.title}>
						{t('pages.booking-confirmed.page-name')}
					</h1>

					<div className={cl.sectionContainer}>
						<FeedbackSection
							type={'success'}
							title={t('pages.booking-confirmed.title')}
							message={t('pages.booking-confirmed.description')}
						/>
					</div>
				</div>
			</div>
		</PageLayout>
	);
};

export default BookingConfirmedPage;
